'use client';
import Translation from '@/client/components/Translation';
import Button from '@/external/components/Button';

type Props = {
  translations: Record<string, string>;
};

const ShowMore: FC<Props> = ({ translations }) => (
  <Button className="font-normal w-28 mt-4" variant="primary">
    <Translation id="general.read_more" translations={translations} />
  </Button>
);

export default ShowMore;
